import Icon from 'components/ui/Icon';
import Image from 'components/ui/Image';
import { Direction } from 'types/Utilities';
import Link from 'components/ui/Link';
import Layout from 'components/ui/Layout';
import { getBrand } from 'data/page/utils/getBrand';
import { StructuredData } from 'components/Pages/Home';
import { getAllReviewsForCount, getAverageRating } from 'data/utils/reviews';
import styles from './intro.module.scss';
import type { GetStaticProps } from 'next';
import type { Brand } from 'types/Brand';

type Props = {
  brand: Brand | null;
  reviewStats: { avg_rating: number; total: number | undefined } | null;
};

const Intro = ({ brand, reviewStats }: Props): JSX.Element => {
  return (
    <Layout
      title="Qunomedical - Pioneering Patient Care & Healthcare Solutions"
      metaDescription="Your gateway to top-tier healthcare. Choose Qunomedical for patient care or Qunosuite for advanced healthcare tools. Elevate patient journeys."
      footer={null}
      brand={brand}
      noLayout
    >
      <StructuredData reviewStats={reviewStats} />
      <div className={styles.container}>
        <div className={styles.qunomedical}>
          <div>
            <div className={styles.imageWrapper}>
              <Image
                className={styles.leftTopImage}
                src="/v2/images/qm_2.jpg"
                alt=""
                height={140}
                width={140}
              />
              <Image
                src="/v2/images/qm_1.jpg"
                alt=""
                height={400}
                width={310}
              />
              <Image
                className={styles.rightBottomImage}
                src="/v2/images/qm_3.svg"
                alt=""
                height={77}
                width={145}
              />
            </div>
            <div className={styles.content}>
              <Icon
                className={styles.qunomedicalLogo}
                type="Qunomedical"
                width="16rem"
                color="#265161"
              />
              <h1>
                Find the right doctor and treatment for your needs{' '}
                <Icon
                  type="Angle"
                  direction={Direction.RIGHT}
                  width="11rem"
                  color="#265161"
                  className={styles.arrow}
                />
              </h1>
            </div>
          </div>
          <Link href="/en" className="stretched-link" legacyBehavior={false} />
        </div>
        <div className={styles.qunosuite}>
          <div>
            <div className={styles.imageWrapper}>
              <Image
                className={styles.leftBottomImage}
                src="/v2/images/qs_3.png"
                alt=""
                height={140}
                width={140}
              />
              <Image
                src="/v2/images/qs_1.jpg"
                alt=""
                height={400}
                width={310}
              />
              <Image
                className={styles.rightTopImage}
                src="/v2/images/qs_2.jpg"
                alt=""
                height={140}
                width={140}
              />
            </div>
            <div className={styles.content}>
              <Icon
                className={styles.qunosuiteLogo}
                type="Qunosuite"
                width="11rem"
              />
              <p>
                Patient Relationship Management Solution{' '}
                <Icon
                  type="Angle"
                  direction={Direction.RIGHT}
                  width="11rem"
                  color="#fff"
                  className={styles.arrow}
                />
              </p>
            </div>
          </div>
          <Link
            href="https://www.qunosuite.com/en"
            className="stretched-link"
            legacyBehavior={false}
          />
        </div>
      </div>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const reviews = await getAllReviewsForCount();
  const reviewStats = {
    avg_rating: getAverageRating(reviews),
    total: reviews?.length,
  };

  return {
    props: {
      brand: await getBrand(process.env.BRAND_ID || null, 'en'),
      reviewStats,
    },
  };
};

export default Intro;
